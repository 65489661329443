import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import Button from "components/Button"

const CoursesContent = () => {
  return (
    <section className="courses-content">
      <div className="container">
        <div className="courses-content__block">
          <div className="row">
            <div className="col-12">
              <p className="courses-content__text">
                Zapraszam Cię do zapoznania się z ofertą moich szkoleń jakie
                przygotowalam specjalnie dla Ciebie i obiecuję, że zrobie
                wszystko, aby jak najlepiej przygotować Cię do pracy ze stopami.
              </p>
            </div>
          </div>
          <div className="courses-content__row row">
            <div className="col-md-6 col-12">
              <div className="courses-content__left">
                <div className="courses-content__img">
                  <img
                    src={
                      require("assets/images/courses/kurs_stacjonarny.jpg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="courses-content__info">
                  <Link to="/szkolenia/szkolenia-stacjonarne/">
                    Szkolenia stacjonarne
                  </Link>
                  <Button
                    type="button"
                    className="button-dark-green"
                    to="/szkolenia/szkolenia-stacjonarne/"
                  >
                    Dowiedz się więcej
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="courses-content__right">
                <div className="courses-content__img">
                  <img
                    src={
                      require("assets/images/courses/kurs_online.jpg").default
                    }
                    alt=""
                  />
                </div>
                <div className="courses-content__info">
                  <a
                    href="https://forfeet.pl/produkty/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Szkolenia online
                  </a>
                  <a
                    href="https://forfeet.pl/produkty/"
                    target="_blank"
                    rel="noreferrer"
                    className="button button-dark-green"
                  >
                    Dowiedz się więcej
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoursesContent
