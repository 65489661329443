import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import CoursesContent from "page_components/courses/CoursesContent"

const Courses = () => {
  const breadcrumbs_data = [
    {
      name: "Szkolenia",
      href: "/szkolenia/",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Szkolenia",
        description:
          "Jeśli jesteś zainteresowany jakimkolwiek szkoleniem lub warsztatem organizowanym w naszym Centrum napisz do nas, a my stworzymy indywidualne szkolenie podologiczne dostosowane do Twoich oczekiwań.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Szkolenia" />
      <CoursesContent />
    </Layout>
  )
}

export default Courses
